<template>
  <div>
    <vs-row>
      <!-- <vs-col vs-w="10" vs-type="flex" vs-justify="flex-end">
        <vs-button
          color="primary"
          @click="addNewClient"
        >创建新学员</vs-button>
      </vs-col> -->
      <vs-col vs-w="12" vs-type="flex" vs-justify="flex-end">
        <vs-button
          color="primary"
          @click="refresh"
        >刷新</vs-button>
      </vs-col>
    </vs-row>

    <vs-table search :data="tableClients" max-items="10" pagination>
      <template slot="header">
        <h3>学员</h3>
      </template>
      <template slot="thead">
        <vs-th>姓名</vs-th>
        <vs-th>移动电话</vs-th>
        <vs-th>教练ID</vs-th>
        <vs-th>归属组织</vs-th>
        <vs-th>状态</vs-th>
        <!-- <vs-th>教练指派</vs-th> -->
        <vs-th>删除</vs-th>
      </template>

      <template slot-scope="{data}">
        <vs-tr :key="indextr" v-for="(tr, indextr) in data">
          <vs-td class="field0" sort-key="tr.clientName">{{tr.clientUser.name}}</vs-td>
          <vs-td sort-key="tr.clientMobile">{{tr.clientUser.mobile}}</vs-td>
          <!-- <vs-td>{{tr.trainer_user?tr.trainer_user.name:'-'}}</vs-td> -->
          <vs-td>{{tr.client.trainer_id?tr.client.trainer_id:'-'}}</vs-td>
          <vs-td>{{tr.organization?tr.organization.name:'-'}}</vs-td>
          <vs-td>
            <vs-chip v-if="tr.client.state=='active'" color="success">激活的</vs-chip>
            <vs-chip v-else color="danger">归档的</vs-chip>
          </vs-td>
            <!-- <vs-button v-if="tr.client.state=='active'" color="danger" icon="power_off" @click="archiveClient(tr.client)">去归档</vs-button>
            <vs-button v-else color="success" icon="power"  @click="activeClient(tr.client)">去激活</vs-button> -->
          <!-- <vs-td>
            <vs-button color="success" icon="power" @click="assignTrainer(tr.client)">{{tr.client.trainer_id?'重新':''}}指派教练</vs-button>
          </vs-td> -->
          <vs-td>
            <vs-button color="danger" siza="small" @click="deleteClient(tr)">删除</vs-button>
            <!-- <vs-dropdown>
              <span>
                更多...
                <vs-icon class icon="expand_more"></vs-icon>
              </span>
              <vs-dropdown-menu>
                <vs-dropdown-item @click="resetPassword(tr)">重置密码</vs-dropdown-item>
              </vs-dropdown-menu>
            </vs-dropdown> -->
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>

    <!-- <NewClient
      :trainer_id="0"
      :show="showNewClient"
      :active="true"
      @hide="showNewClient=false"
    />

    <vs-popup title="设置教练" :active.sync="showTransfer">
      <TeamTransfer :client="selectedClient" @confirm="transferCoachSelected" />
    </vs-popup> -->
  </div>
</template>

<script>
// import NewClient from "@/views/components/coach/client/NewClient.vue";
// import TeamTransfer from "@/views/components/coach/team/TeamTransfer.vue";

import { mapState } from "vuex";

//common functions
import baseFun from "@/views/components/coach/baseFunctions.js";

export default {
  mixins: [baseFun],

  data() {
    return {
      selectedTrainer: null,
      selectedClient: null,

      showNewClient: false,
      showTransfer: false
    };
  },

  created() {
    this.$store.commit("adminPageIdx", 2);
    this.$store.dispatch("getAllClients");
  },

  computed: {
    tableClients(){
      let ary = [];
      if (this.clients) this.clients.forEach(item => {
        if (!item) return;

        let newItem = {
          client:item,
          clientUser:this.getUser(item.user_id),
          trainer:null,
          trainerUser:null,
          organization:null,
          clientName:'',
          clientMobile:''
          };
        if (newItem.clientUser) {
          newItem.clientName = newItem.clientUser.name;
          newItem.clientMobile = newItem.clientUser.mobile;
        }
        if (item.trainer_id) newItem.trainer = this.getTrainer(item.trainer_id);
        if (item.trainer) newItem.trainerUser = this.getUser(item.trainer.user_id);
        if (item.organization_id) newItem.organization = this.getOrganization(item.organization_id);

        ary.push(newItem);
      });

      ary.sort((a,b)=>a.client.organization_id==b.client.organization_id
      ?b.clientUser.name-a.clientUser.name
      :a.client.organization_id-b.client.organization_id);

      return ary;
    },

    ...mapState([
      "activeOrganization",
      "organizations",
      "users",
      "trainers",
      "clients"
    ])
  },

  methods: {
    // addNewClient(trainer) {
    //   this.activeTrainer = trainer;
    //   this.showNewClient = true;
    // },

    // assignTrainer(client) {
    //   //console.log(client.id);
    //   this.selectedClient = client;
    //   this.showTransfer = true;
    // },

    // archiveClient(client) {
    //   let newClient = JSON.parse(JSON.stringify(client));
    //   newClient.state = 'archive';
    //   this.$store.dispatch("updateClient", {client:newClient, vm:this});
    // },

    // activeClient(client){
    //   let newClient = JSON.parse(JSON.stringify(client));
    //   newClient.state = 'active';
    //   this.$store.dispatch("updateClient", {client:newClient, vm:this});
    // },

    // transferCoach(client){
    //   // console.log("transferCoach", client);
    //   this.selectedClient = client;
    //   this.showTransfer = true;
    // },

    // transferCoachSelected(payload){
    //   // console.log(payload);
    //   this.showTransfer = false;

    //   let newClient = JSON.parse(JSON.stringify(this.selectedClient));
    //   newClient.trainer_id = payload.new_trainer_id;
    //   this.$store.dispatch("updateClient", {vm:this, client:newClient});
    // },

    deleteClient(client){
      this.selectedClient = client.client;
      this.$vs.dialog({
        type: 'confirm',
        color:'danger',
        title: `警告`,
        text: ' 删除动作不可恢复，是否删除"' + client.clientUser.name + '"？ ',
        "accept-text": '确定',
        "cancel-text": '取消',
        accept:this.deleteClientProcess
      })
    },

    deleteClientProcess(){
      let client = this.selectedClient;
      this.$store.dispatch("deleteClient", {vm:this, client});
    },

    refresh(){
      this.$store.dispatch("getAllClients")
    }
  },

  components: {
    // NewClient,
    // TeamTransfer
  }
};
</script>

<style scoped>
.field0 {
  white-space: nowrap;
}
</style>
